<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">Auto Glass DORAL <small>CRM</small></router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/invoices"><i class="fa-solid fa-file-invoice-dollar"></i> Invoices</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/quotes"><i class="fa-solid fa-file-invoice"></i> Quotes</router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-user fa-fw"></i> {{authUser.email}} 
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <li><router-link class="dropdown-item" to="/users"><i class="fa-solid fa-users fw"></i> Users</router-link></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" @click.prevent="logout"><i class="fa-solid fa-arrow-right-from-bracket fw"></i> Exit</a></li>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Vuex from 'vuex'
export default {
  name: 'headerMenu',
  computed:{
  ...Vuex.mapState({
        authUser: state => state.auth.user,
        authToken: state => state.auth.token,
      })
  },
  methods: {
    logout(){
      this.$store.dispatch("auth/logout");
      window.location.reload();
    }
  }
}
</script>

<style>
  li.nav-item a.router-link-active{
    color: #ffffff !important;
    font-weight: bold !important;
  }

  li.dropdown a.router-link-active{
    background-color: #dedede;
    color: black;
  }

</style>