<template>
    <div>
        <div class="preview-file" v-for="(file, index) of files">
            <img :src="getThumbnailSrc(file.src, file.name)" alt="" width="105" height="105" class="input-preview-file-img" @click="openViewer(index)"  :style="[{'cursor': enableViewer?'pointer':'auto'}]"/> 
            <a class="input-delete-file" @click="deleteFile(file, index)" v-show="enableDelete">
                <i class="fa fa-trash"> </i>
            </a>
        </div>
        <file-viewer :ref="`file-viewer-${id}`" :id="id" :files="files" />
    </div>
</template> 

<script>
import fileTypeIs from '../../mixins/fileTypeIs';
import fileViewer from '../fileViewer';

export default {
    name: 'file-list',
    components: {
        'file-viewer': fileViewer
    }, 
    mixins: [fileTypeIs],
    props: {
        files:{
            type: Array,
            default: []
        },
        enableDelete:{
            type: Boolean,
            defaul: false,
        },
        enableViewer:{
            type: Boolean,
            defaul: false,
        },
        id:{
            type: String,
            required: true
        }
    },
    methods:{
        deleteFile(file, index){
            this.$emit('delete',{...file,'index': index});
        },
        openViewer(index){
            if(this.enableViewer){
                console.log(index);
                this.$refs[`file-viewer-${this.id}`].open(index);
            }
        },
        getThumbnailSrc(fs, fn){
            if(this.fileTypeIs('pdf', fn.split('.').pop())){
                return require("../../assets/images/pdf.jpeg");
            }else{
                return fs
            }
        }
    }
}
</script>
