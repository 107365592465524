<template>
    <div class="container">
        <alert ref="quoteListAlert" :body="alert.message" :type="alert.type"/>
        <router-link type="button" class="btn btn-primary" to="/quote/create" style="float:right;">New <i class="fas fa-plus"></i></router-link>
        <h2>Quotes</h2>
        <div class="card" style="margin-top:10px">
            <custom-table source="/quote/list" ref="quoteList" :options="{sort:{number:-1}}">
                <template #header>
                    <th  width="30%">Quote</th>
                    <th  width="40%">Customer Name</th>
                    <th  width="10%">Date</th>
                    <th width="20%"></th>
                </template>
                <template #body="{row, index}">
                    <td  width="30%"><router-link class="text-decoration-none" :to="`/quote/edit/${row._id }`"> {{ row.number }} </router-link></td>
                    <td  width="40%">{{ row.customer.full_name }} </td>
                    <td  width="10%">{{ row.customer.date }} </td>
                    <td width="20%">
                        <router-link class="btn btn-link btn-sm" title="Edit quote" :to="`/quote/edit/${row._id }`"><i class="fas fa-edit"></i></router-link>
                        <button class="btn btn-link btn-sm" title="Delete quote" @click="deleteQuote(row._id)"><i class="fas fa-trash-alt"></i></button>
                        <button class="btn btn-link btn-sm" title="PDF quote" @click="openViewerPDF(row._id)"><i class="fas fa-file-pdf"></i></button>
                        <button class="btn btn-link btn-sm" title="Send Email quote" @click="openEmail(row)"><i class="fas fa-envelope"></i></button>
                    </td>
                </template>
            </custom-table>
        </div>
        <file-viewer ref="v_quote_pdf" id="v_quote_pdf" :files="files" />
        <email-modal 
            ref="quote-email-modal" 
            type="quote" 
            :typeId="email.typeId" 
            :recipient="email.recipient" 
            @onSend="openAlert('Email sent successfully', 'success')" 
            @onError="openAlert('cannot send Email', 'danger')" 
        />
    </div>
</template>

<script>
import table from '../../../components/table'
import isMobile from '../../../helpers/isMobile'
import request from '../../../helpers/request'
import fileViewer from '../../../components/fileViewer';
import emailModal from '../../../components/emailModal';
import alert from '../../../components/alert';
import Vuex from 'vuex'

export default {
  name: 'quotes',
  components:{
    'custom-table': table,
    'file-viewer':fileViewer,
    'email-modal': emailModal,
    'alert': alert
  },
  computed:{
    _app(){return window.app},
	...Vuex.mapState({
      auth: state => state.auth.user,
      authToken: state => state.auth.token,
    })
  },   
  methods:{
    openAlert(msg, type){
        this.alert.message = msg;
        this.alert.type = type;
        this.$refs['quoteListAlert'].open();
        setTimeout(() => {
            this.$refs['quoteListAlert'].close();
        }, 2500);
    },  
    async deleteQuote(quoteId){
        try{
            const confirm = window.confirm("Are you sure, of delete this quote?")
            if(confirm){
                await request('POST', '/quote/delete', {quote: quoteId}, this.authToken);
                this.openAlert('quote deleted successfully', 'success');
                this.$refs.quoteList.getRecords();
            }
        }catch(err){
            this.openAlert(`cannot deleted quote : ${err}`,'danger');
        }
    },
    async openEmail(quote){
        this.email.typeId = quote._id;
        this.email.recipient = quote.customer.email;
        this.$refs['quote-email-modal'].show();
        
    },
    async openViewerPDF(quoteId){
        try{
            let res = await request('POST',`/pdf/create`,{type:"quote", typeId:quoteId}, this.authToken);
            this.files = res.files.map(f => {
                return {'src':`${this._app.API}/quote/${quoteId}/pdf/${f}?token=${this.authToken}`, 'name': f}
            })
            if(!isMobile())this.$refs['v_quote_pdf'].open();
            else {
                for(let file of this.files){
                    window.open(file.src, '_blank');
                }
            }
        }catch(err){
            console.log(err);
        }
    }
  },
  data(){
      return {
        quotes : [],
        email :{
            recipient: null,
            typeId: null,
            type: 'quote'
        },
        alert:{
            message: null,
            type: null
        },
        files : []
      }
  }
}
</script>