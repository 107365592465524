<template>
    <form @submit.prevent="save" class="row">

        <div class="col-12 clarfix">
            <router-link class="btn btn-danger float-end" style="margin-left:5px" to="/quotes" >Cancel <i class="fa-solid fa-xmark"></i> </router-link>
            <button type="submit" class="btn btn-primary float-end">Save <i class="far fa-save"></i> </button>
            <a class="btn btn-light float-end" style="margin-right:10px" title="PDF quote" @click.prevent="openViewerPDF()" v-if="quoteId"><i class="fas fa-file-pdf"></i></a>
        </div>

        <div class="accordion col-12" id="quoteInfo" style="margin-top:15px">
            <div class="accordion-item">
                <h2 class="accordion-header" id="customerHeader">
                    <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#customerCollapse" aria-expanded="true" aria-controls="customerCollapse">
                        CUSTOMER
                    </button>
                </h2>
                <div id="customerCollapse" class="accordion-collapse collapse show" aria-labelledby="customerHeader" >
                    <div class="accordion-body row">

                        <div class="mb-3 col-md-6 col-12">
                            <label for="customer_name">Full Name</label>
                            <input type="text" class="form-control" id="customer_name" v-model="quote.customer.full_name" required>
                        </div>

                        <div class="mb-3 col-md-6 col-12">
                            <label for="customer_home_phone">Home Phone</label>
                            <input type="text" class="form-control" id="customer_home_phone" v-model="quote.customer.home_phone">
                        </div>

                        <div class="mb-3 col-md-6 col-12">
                            <label for="customer_cell_phone">Cell Phone</label>
                            <input type="text" class="form-control" id="customer_cell_phone" v-model="quote.customer.cell_phone">
                        </div>

                        <div class="mb-3 col-md-6 col-12">
                            <label for="customer_email">Email</label>
                            <input type="text" class="form-control" id="customer_email" v-model="quote.customer.email">
                        </div>
                        
                        <address-form :address="quote.customer.address"></address-form>
                    </div>
                </div>
            </div>

            <div class="mb-3 col-md-7 col-12 form-check" style="margin-top:15px">
                <input class="form-check-input" type="checkbox" id="irwe_fc" v-model="quote.irwe">
                <label class="form-check-label" for="irwe_fc"> I request a written estimate </label>
            </div>

            <div class="mb-3 col-md-7 col-12 form-check">
                <input class="form-check-input" type="checkbox" id="idnrwei_fc" v-model="quote.idnrwei">
                <label class="form-check-label" for="idnrwei_fc"> I do not request a written estimate if </label>
            </div>

            <div class="mb-3 col-md-7 col-12">
                <label for="repairCostNotExced">Repair cost does not exceed</label>
                $ <input type="number" step="any" class="form-control" id="repairCostNotExced" v-model="quote.repairCostNotExced">
            </div>

            <div class="mb-3 col-md-7 col-12 form-check" style="margin-top:15px">
                <input class="form-check-input" type="checkbox" id="idnrwe" v-model="quote.idnrwe">
                <label class="form-check-label" for="idnrwe"> I do not request a written estimate </label>
            </div>

            <div class="mb-3 col-md-7 col-12">
                <label for="reasemblyFee">Reasembly fee</label>
                $ <input type="number" step="any" class="form-control" id="reasemblyFee" v-model="quote.reasemblyFee">
            </div>

            <quote-items class="mb-3 col-12" :items="quote.items"></quote-items>

            <div class="mb-3 col-md-6 col-12">
                <label for="tax">Tax</label>
                <div class="input-group mb-3">
                    <input type="number" step="any" class="form-control" id="tax" v-model="quote.tax">
                    <span class="input-group-text">%</span>
                </div>
            </div>

            <div class="mb-3 col-md-6 col-12">
                <label for="date">Date</label>
                <input type="date" class="form-control" id="date" v-model="quote.date">
            </div>

            <div class="mb-3 col-md-6 col-12">
                <label for="signature_quote">Customer Signature</label>
                <signature id="signature_quote" :img="quote.signature" @onInput="signatureInput" style="margin-top:9px"/>
            </div> 
        </div>

        <div class="col-12 clarfix">
            <router-link class="btn btn-danger float-end" style="margin-left:5px" to="/quotes" >Cancel <i class="fa-solid fa-xmark"></i> </router-link>
            <button type="submit" class="btn btn-primary float-end">Save <i class="far fa-save"></i> </button>
            <a class="btn btn-light float-end" style="margin-right:10px" title="PDF quote" @click.prevent="openViewerPDF()" v-if="quoteId"><i class="fas fa-file-pdf"></i></a>
        </div>
        <spinner ref="quoteFormSpinner"/>
    </form>
</template>

<script>
import addressForm from '../addressForm'
import quoteItems from '../items'
import signature from '../signature'
import request from '../../helpers/request'
import spinner from '../spinner'

import Vuex from 'vuex'
import moment from 'moment'

export default {
    name: "quoteForm",
    components:{
        'address-form': addressForm,
        'quote-items': quoteItems,
        'signature': signature,
        'spinner':  spinner
    },
    watch:{
        quoteId: function(val){
            if(val!== null){
                this.getQuote();
            }else{
                this.newQuote();
            }
        }
    },
    computed:{
        _app(){
            return window.app
        },
		...Vuex.mapState({
            authUser: state => state.auth.user,
            authToken: state => state.auth.token,
        })
    },
    mounted(){
        if(this.quoteId){
            this.getQuote();
        }
    },
    props:{
        quoteId:{
            type: String,
            default: null
        }
    },
    methods:{
        async save(){
            try{
                let res;
                this.$refs.quoteFormSpinner.show();
                if(this.quoteId){
                    if(this.quote._id)delete this.quote._id;
                    res = await request('POST', `/quote/update?token=${this.authToken}`, {quoteId:this.quoteId, quote: this.quote}, null, true);
                }else{
                    res = await request('POST', `/quote/create?token=${this.authToken}`, {quote: this.quote}, null, true);
                    this.newQuote();
                }
                setTimeout(() => {
                    this.$refs.quoteFormSpinner.hide();
                    this.$emit('onSave', {quoteId:res._id, type:this.quoteId?'edit':'create'});
                }, 1000);
            }catch(err){
                console.log(err);
                this.$emit('onError', err);
            }
        },
        async getQuote(){
            try{
                this.quote = await request('POST', '/quote/read', {quote: this.quoteId}, this.authToken);
                this.$emit('onRead', this.quote);
            }catch(err){
                console.log(err);
            }
        },
        signatureInput(evt){
            this.quote.signature = evt;
        },
        async openViewerPDF(){
            try{
                this.$refs.quoteFormSpinner.show();
                let res = await request('POST',`/pdf/create`,{type:"quote", typeId:this.quoteId}, this.authToken);
                this.pdfFiles = res.files.map(f => {
                    return {'src':`${this._app.API}/quote/${this.quoteId}/pdf/${f}?token=${this.authToken}`, 'name': f}
                })
                this.$refs.quoteFormSpinner.hide();
                setTimeout(() => {
                    for(let file of this.pdfFiles){ window.open(file.src, '_blank');}
                }, 500);
            }catch(err){
                console.log(err);
            }
        }, 

        newQuote(){
            this.pdfFiles = [];
            this.quote = {
                "irwe": false,
                "idnrwei": false,
                "idnrwe": false,
                repairCostNotExced: null,
                reasemblyFee : null,
                items: [],
                tax: null,
                date: `${moment().format('YYYY-MM-DD')}`,
                signature:null,
                customer: {
                    full_name: null,
                    home_phone: null,
                    cell_phone: null,
                    email: null,
                    address:{
                        country: 'United States',
                        address1: null,
                        address2: null,
                        city:null,
                        state: null,
                        zip: null
                    }
                }
            }
        }
    },
    data(){
        return{
            pdfFiles: [],
            quote:{
                "irwe": false,
                "idnrwei": false,
                "idnrwe": false,
                repairCostNotExced: null,
                resemblyFree : null,
                items: [],
                tax: null,
                date: `${moment().format('YYYY-MM-DD')}`,
                signature:null,
                customer: {
                    full_name: null,
                    home_phone: null,
                    cell_phone: null,
                    email: null,
                    address:{
                        country: 'United States',
                        address1: null,
                        address2: null,
                        city:null,
                        state: null,
                        zip: null
                    }
                }
            }
        }
    }
}
</script>