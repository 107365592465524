<template>
    <div class="container">
        <breadcrum :routes="[{name:'Users', path:'/users', active:true}]"/>
        <button type="button" class="btn btn-primary" @click="addUser()" style="float:right;">New <i class="fas fa-plus"></i></button>
        <h2>Users</h2>
        <div class="card" style="margin-top:10px">
            <custom-table source="/user/list" ref="userList">
                <template #header>
                    <th>User</th>
                    <th></th>
                </template>
                <template #body="{row, index}">
                    <td>{{ row.email }} </td>
                    <td>
                        <button class="btn btn-link btn-sm" title="Edit User" @click="editUser(row)" ><i class="fas fa-edit"></i></button>
                        <button class="btn btn-link btn-sm" title="Delete User" @click="deleteUser(row._id)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                </template>
            </custom-table>
        </div>

        <!-- Modal -->
        <div class="modal fade" tabindex="-1" id="userFormModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">User</h5>
                        <button type="button" class="btn-close" @click="clearUserForm" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <user-form ref="userForm" :user="activeUser" @saved="savedUser" @error="error" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import breadcrum from '../../components/breadcrum'
import table from '../../components/table'
import request from '../../helpers/request'
import userForm from '../../components/userForm'
import Vuex from 'vuex'
import {Modal} from 'bootstrap'

export default {
  name: 'users',
  components:{
    'user-form': userForm,
    'custom-table': table,
    'breadcrum': breadcrum
  },
  computed:{
		...Vuex.mapState({
      auth: state => state.auth.user,
      authToken: state => state.auth.token,
    })
  },
  mounted(){
    this.userModal = new Modal(document.getElementById('userFormModal'));
  },    
  methods:{
    error(err){
        console.log(err);
    },
    savedUser(){
        this.activeUser = {
            _id: null,
            email: null
        };
        this.$refs.userForm.clear();
        this.userModal.hide();
        this.getUsers();
    },
    addUser(){
        this.activeUser = {
            _id: null,
            email: null
        };
        this.userModal.show();
    },
    editUser(user){
        this.activeUser = user;
        this.userModal.show();
    },
    async deleteUser(userId){
        try{
            const confirm = window.confirm("Are you sure, of delete this user?")
            if(confirm){
                await request('POST', '/user/delete', {user: userId}, this.authToken);
                this.getUsers();
            }
        }catch(err){
            console.log(err);
        }
    },
    getUsers(){
        this.$refs.userList.getRecords();
    },
    clearUserForm(){
        this.$refs.userForm.clear();
    }
  },
  data(){
      return {
        userModal: null,
        users : [],
        activeUser : {
            _id: null,
            email: null
        }
      }
  }
}
</script>