<template>
    <div class="modal fade" :id="`file-viewer-modal-${id}`" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content" style="background-color: rgb(19 19 19);color: white;">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
                    <button type="button" style="color: white;" class="btn btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body">                
                    <div class="d-flex flex-row justify-content-between">
                        <div class="pointer d-flex flex-column justify-content-center align-items-center fileViewerControls" v-show="files.length > 1" @click="previous">
                            <i class="fas fa-chevron-left"></i>
                        </div>
                        <template v-if="activeFile && activeFile.name">
                            <div class="fileViewerContainer" v-if="activeFile.name.split('.')[1] == 'pdf'">
                                <iframe frameborder="0" scrolling="no" :style="`height: 800px; width: 1000px;`" :src="files[currentIndex].src"></iframe> 
                            </div>
                            <div class="fileViewerContainer" v-else>
                                <img class="fileViewerImage" :style="`max-height: ${height}; max-width: ${width};`" :src="files[currentIndex].src" >
                            </div>
                        </template>
                        <div class="pointer d-flex flex-column justify-content-center align-items-center fileViewerControls" v-show="files.length > 1" @click="next">
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Modal} from 'bootstrap'
    import fileTypeIs from '../../mixins/fileTypeIs';
    export default {
        name: "file-viewer-modal",
        mixins: [fileTypeIs],
        props: {
            id:{
                type:String,
                required: true
            },
            title:{
                type:String
            },
            width:{
                type: String,
                default: '80%'
            },
            height:{
                type: String,
                default: '80%'
            },
            files: {
                type: Array,
                default: () => []
            },
            selectedIndex: {
                type: Number,
                default: 0
            },
        },
        mounted(){
            this.fileViewerModal = new Modal(document.getElementById(`file-viewer-modal-${this.id}`));
        },
        computed: {
            'activeFile': function(){
                return this.files[this.currentIndex];
            }
        },
        methods: {
            previous() {
                let index = this.currentIndex - 1
                if(index >= 0){
                    this.currentIndex = index;
                } else{
                    this.currentIndex = this.files.length - 1;
                }  
            },
            next(){
                let index = this.currentIndex + 1
                if(index < this.files.length){
                    this.currentIndex = index;
                } else{
                    this.currentIndex = 0;
                }
            },
            open(index = null){
                if(index!=null) this.setCurrentIndex(index);
                this.fileViewerModal.show();
            },
            close(){
                this.fileViewerModal.close();
            },
            setCurrentIndex(val){
                this.currentIndex = val;
            }
        },
        data: function () {
            return {
                fileViewerModal: null, 
                currentIndex: 0
            }
        },
        watch: {
            selectedIndex:function(newVal, oldVal) {
                this.setCurrentIndex(newVal);
            },
        }
    }
</script>

<style>
.fileViewerContainer
{
    width:100%;           
    height:100%;              
    border:none;              
    padding:0px;              
    margin:0px;
}
.fileViewerImage
{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.fileViewerControls{
    width: 4vw;
    font-size: 40px;
    border-radius: 10px;
    z-index: 200;
    height: 70vh;
    cursor: 'pointer'
}
.fileViewerControls:hover{
    background:gray
}
</style>