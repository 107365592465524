export default {
    methods: {
        fileTypeIs(type, extension){
            let fileExtensions = [];
            switch (type) {
              case 'image':
                fileExtensions = ['bmp','jpg','jpeg','gif','png','eps','raw','nef'];
              case 'audio':
                fileExtensions = ['mp3','mpeg','wav','ogg','m4p','wav','webm'];
                break;
              case 'video':
                fileExtensions = ['webm','mkv','flv','ogv','ogg','avi','wmv','mp4', 'm2v', 'm4v'];
              case 'pdf':
                fileExtensions = ['pdf'];
              default:
                break;
            }
            return !!fileExtensions.find(ext => ext === extension);
        }
    }
}