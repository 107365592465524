<template>
    <form @submit.prevent="save">
      <div class="form-group">
        <label for="userEmail">E-mail</label>
        <input type="email" class="form-control" id="userEmail" v-model="user.email" required="true" autocomplete="off" :disabled="this._user._id !== null">
      </div>
      <div class="form-group">
        <label for="userPassword">Password</label>
        <input type="password" class="form-control" id="userPassword"  v-model="password" required="true" autocomplete="new-password">
      </div>
      <div class="form-group">
        <label for="userRepeatPassword">Repeat password</label>
        <input type="password" class="form-control" id="userRepeatPassword" v-model="verifyPassword" required="true" autocomplete="new-password">
      </div>
      <button style="margin-top:30px" type="submit" class="btn btn-primary">Save <i class="far fa-save fw"></i> </button>
    </form>
</template>

<script>
import request from '../../helpers/request'
import Vuex from 'vuex'

export default {
    name: "userForm",
     computed:{
		...Vuex.mapState({
            authUser: state => state.auth.user,
            authToken: state => state.auth.token,
        })
    },
    props:{
        user:{
            type: Object,
            default: () => {}
        }
    },
    watch:{
        user: function(newVal){
            this._user = JSON.parse(JSON.stringify(newVal));
        }
    },
    methods:{
        async save(){
            try{
                if(this.password == this.verifyPassword){
                    if(this._user._id){
                        const res = await request('POST', '/user/change_password', {user: this._user._id, password: this.password}, this.authToken);
                        this.$emit('saved', res);
                    }else{
                        delete this._user._id;
                        const res = await request('POST','/user/create',{...this.user, password: this.password},this.authToken);
                        this.$emit('saved', res);
                    }
                }else{
                    alert("The password must be the same!!!");
                }

                this._user={
                    _id: null,
                    email: null
                }
                
            }catch(err){
                this.$emit('error', err);
            }
        },
        clear(){
            this._user = {_id: null,email: null};
            this.password =  null;
            this.verifyPassword = null;
        }
    },
    data(){
        return{
            _user:{
                _id: null,
                email: null
            },
            password: null,
            verifyPassword: null
        }
    }
}
</script>