<template>
    <div class="container">
        <h2>Quote <small class="text-muted" v-if="quoteNumber">No. {{quoteNumber}}</small> </h2>
        <div class="row">
            <div class="col-12">
                <alert ref="quoteListAlert" :body="alert.message" :type="alert.type"/>
                <quote-form :quoteId="id" @onSave="onSave" @onRead="onRead" @onError="onError"/>
            </div>
        </div>
    </div>
  </template>
  <script>
    import alert from '../../../components/alert';
    import quoteForm from "../../../components/quoteForm"
    export default {
        name: 'quote',
        components: {
            'quote-form' : quoteForm,
            'alert': alert
        },
        methods:{
            openAlert(msg, type){
                this.alert.message = msg;
                this.alert.type = type;
                this.$refs['quoteListAlert'].open();
                setTimeout(() => {
                    this.$refs['quoteListAlert'].close();
                }, 2500);
            },
            onRead(evt){
                this.quoteNumber = evt.number;
            },
            onSave(evt){
                console.log(evt);
                this.openAlert(`quote ${evt.type=='create'?'Created':'Updated'} Succesfully`, 'success');
                window.location.href = `/quote/edit/${evt.quoteId}`;
            },
            onError(err){
                this.openAlert(`cannot create quote: ${err}`, 'danger');
            }
        },  
        computed:{
            type(){
                return this.$route.params.type;
            },
            id(){
                return this.$route.params.id||null;
            }
        },
        data(){
            return {
                alert:{
                    message: null,
                    type: null
                },
                quoteNumber: null
            }
        }
    }
  </script>