<template>
    <form id="invoiceForm" @submit.prevent="save" class="row">
        <div class="col-12 clearfix" style="margin-top:15px">
            <router-link class="btn btn-danger float-end" style="margin-left:5px" to="/invoices" >Cancel <i class="fa-solid fa-xmark"></i> </router-link>
            <div class="btn-group dropend float-end">
                <button type="submit" class="btn btn-primary">Save <i class="far fa-save"></i></button>
                <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropright</span>
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" style="cursor:pointer" @click="submitInvoice(true)">Save and Close</a></li>
                    <!-- <li><a class="dropdown-item" style="cursor:pointer" @click="save(false, true)">Save and Open PDF's</a></li> -->
                </ul>
            </div>
            <template v-if="invoiceId">
                <a class="btn btn-light float-end" style="margin-right:10px" title="PDF invoice and Attachments" @click.prevent="openViewerPDF(null,'invoice&Attachments.pdf')" v-if="invoiceFilesCopy.length > 0"><i class="fa-solid fa-file-circle-plus"></i></a>
                <a class="btn btn-light float-end" style="margin-right:10px" title="PDF invoice" @click.prevent="openViewerPDF(null,'invoice.pdf')"><i class="fas fa-file-pdf"></i></a>
            </template>
        </div>
        <div class="accordion col-12" id="invoiceInfo" style="margin-top:15px">
            <div class="accordion-item">
                <h2 class="accordion-header" id="customerHeader">
                <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#customerCollapse" aria-expanded="true" aria-controls="customerCollapse">
                    CUSTOMER
                </button>
                </h2>
                <div id="customerCollapse" class="accordion-collapse collapse show" aria-labelledby="customerHeader" >
                    <div class="row accordion-body">

                        <div class="mb-3 col-md-6 col-12">
                            <label for="customer_name">Full Name</label>
                            <input type="text" class="form-control" id="customer_name" v-model="invoice.customer.full_name" required>
                        </div>

                        <div class="mb-3 col-md-6 col-12">
                            <label for="customer_email">Email</label>
                            <input type="text" class="form-control" id="customer_email" v-model="invoice.customer.email" required>
                        </div>

                        <div class="mb-3 col-md-6 col-12">
                            <label for="customer_home_phone">Phone</label>
                            <input type="number" class="form-control" id="customer_home_phone" v-model="invoice.customer.home_phone" required>
                        </div>

                        <div class="mb-3 col-md-6 col-12">
                            <label for="customer_date">Date</label>
                            <input type="date" class="form-control" id="customer_date" v-model="invoice.customer.date">
                        </div>

                        <address-form :address="invoice.customer.address"></address-form>

                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="insuranceHeader">
                    <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#insuranceCollapse" aria-expanded="true" aria-controls="insuranceCollapse">
                        INSURANCE
                    </button>
                </h2>
                <div id="insuranceCollapse" class="accordion-collapse collapse show" aria-labelledby="insuranceHeader" >
                    <div class="row accordion-body">

                        <div class="mb-3 col-md-6 col-12">
                            <label for="insurance_carrier">Insurance Carrier</label>
                            <input type="text" class="form-control" id="insurance_carrier" v-model="invoice.insurance_carrier">
                        </div>

                        <div class="mb-3 col-md-6 col-12" style="margin-top:15px">
                            <label for="insurance_comp_agent">Insurance Comp & Agent</label>
                            <input type="text" class="form-control" id="insurance_comp_agent" v-model="invoice.customer.insurance_comp_agent">
                        </div>
                
                        <div class="mb-3 col-md-6 col-12">
                            <label for="claim_number">Claim Number</label>
                            <input type="text" class="form-control" id="claim_number" v-model="invoice.claim_number">
                        </div>

                        <div class="mb-3 col-md-6 col-12" style="margin-top:15px">
                            <label for="insurance_comp_fax">Policy Number</label>
                            <input type="text" class="form-control" id="insurance_comp_fax" v-model="invoice.customer.policy_number">
                        </div>

                        <div class="mb-3 col-md-6 col-12" style="margin-top:15px">
                            <label for="insurance_comp_phone">Ins. Comp. Phone #</label>
                            <input type="number" class="form-control" id="insurance_comp_phone" v-model="invoice.customer.insurance_comp_phone">
                        </div>
            
                        <div class="mb-3 col-md-6 col-12" style="margin-top:15px">
                            <label for="insurance_comp_fax">Ins. Comp. Fax #</label>
                            <input type="number" class="form-control" id="insurance_comp_fax" v-model="invoice.customer.insurance_comp_fax">
                        </div>
            
                        
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="vehicleInfoHeader">
                <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#vehicleInfoCollapse" aria-expanded="true" aria-controls="vehicleInfoCollapse">
                    VEHICLE
                </button>
                </h2>
                <div id="vehicleInfoCollapse" class="accordion-collapse collapse show" aria-labelledby="vehicleInfoHeader" >
                    <div class="row accordion-body">
                        <div class="mb-3 col-md-4 col-12">
                            <label for="vehicle_year">Year</label>
                            <input type="number" class="form-control" id="customer_name" v-model="invoice.vehicle.year">
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="vehicle_manufacture">Manufacture</label>
                            <input type="text" class="form-control" id="vehicle_manufacture" v-model="invoice.vehicle.manufacture">
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="vehicle_model">Model</label>
                            <input type="text" class="form-control" id="vehicle_model" v-model="invoice.vehicle.model">
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="vehicle_color">Color of Vehicle</label>
                            <input type="text" class="form-control" id="vehicle_color" v-model="invoice.vehicle.color">
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="vehicle_shade_windshield">Shade Windshield</label>
                            <input type="text" class="form-control" id="vehicle_shade_windshield" v-model="invoice.vehicle.shade_windshield">
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="vehicle_body">Body Type</label>
                            <input type="text" class="form-control" id="vehicle_body" v-model="invoice.vehicle.body_type">
                        </div>


                        <div class="mb-3 col-md-6 col-12">
                            <label for="vehicle_vin">V.I.N</label>
                            <input type="text" class="form-control" id="vehicle_vin" v-model="invoice.vehicle.vin">
                        </div>

                        <div class="mb-3 col-md-6 col-12">
                            <label for="vehicle_tag">Tag</label>
                            <input type="text" class="form-control" id="vehicle_tag" v-model="invoice.vehicle.tag">
                        </div>
    
                        <div class="mb-3 col-12">
                            <label for="vehicle_date_loss">Date of Loss</label>
                            <div class="input-group">
                                <input type="date" class="form-control" id="vehicle_date_loss" v-model="invoice.vehicle.date_loss">
                                <input type="time" class="form-control" id="vehicle_date_time" v-model="invoice.vehicle.date_time">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="descriptionInfoHeader">
                <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#descriptionInfoCollapse" aria-expanded="true" aria-controls="descriptionInfoCollapse">
                    DESCRIPTION
                </button>
                </h2>
                <div id="descriptionInfoCollapse" class="accordion-collapse collapse show" aria-labelledby="descriptionInfoHeader" >
                    <div class="row accordion-body">
                        
                        <invoice-items class="mb-3 col-12" :items="invoice.description.items"></invoice-items>

                        <div class="mb-4 row" style="margin-left: 4px;">
                            <div class="col-md-3 col-12 form-check" v-for="(service, key) of invoice.services">
                                <input class="form-check-input" type="checkbox" :value="service" :id="`${key}_fc`" v-model="invoice.services[key]">
                                <label class="form-check-label" :for="`${key}_fc`">
                                    {{ key }}
                                </label>
                            </div>
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="description_labor">Labor</label>
                            <input type="number" step="any" class="form-control" id="description_labor" v-model="invoice.description.labor">
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="description_shop_suplies">Shop Supplies</label>
                            <input type="number" step="any" class="form-control" id="description_shop_suplies" v-model="invoice.description.shop_suplies">
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="description_tax">Tax</label>
                            <div class="input-group mb-3">
                                <input type="number" step="any" class="form-control" id="description_tax" v-model="invoice.description.tax">
                                <span class="input-group-text" id="basic-addon2">%</span>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="description_comments">Comments</label>
                            <textarea class="form-control" id="description_comments" rows="6" v-model="invoice.description.comments" />
                        </div>

                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="supportFilesHeader">
                <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#supportFilesCollapse" aria-expanded="true" aria-controls="supportFilesCollapse">
                    SUPPORT FILES
                </button>
                </h2>
                <div id="supportFilesCollapse" class="accordion-collapse collapse show" aria-labelledby="supportFilesHeader" >
                    <div class="accordion-body">
                        <div class="mb-3 col-12">
                            <label class="fw-bold"></label>
                            <div class="row">
                                <div class="col-12 col-12 text-muted" v-if="(invoiceId && invoiceFiles.length > 0)">
                                    <label for="uploadFiles">Uploaded Files</label>
                                    <file-list id="invoiceUploadedFileList" :files="invoiceFiles" :enableViewer="true" :enableDelete="true"  @delete="removeInvoiceFiles"/>
                                </div>
                                <div class="mb-3 col-12">
                                    <label for="uploadFiles" style="margin-top:10px">Add New Files</label>
                                    <file-input @onInput="setInvoiceNewFiles" ref="invoice-input-files"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="notesHeader">
                <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#notesCollapse" aria-expanded="true" aria-controls="notesCollapse">
                    NOTES
                </button>
                </h2>
                <div id="notesCollapse" class="accordion-collapse collapse show" aria-labelledby="notesHeader" >
                    <div class="accordion-body">
                        <div class="mb-3">
                            <label for="installer_notes">Installer Notes</label>
                            <textarea class="form-control" id="installer_notes" v-model="invoice.installer_notes" />
                        </div>
            
                        <div class="mb-3 col-12">
                            <label for="billing_notes">Billing Notes</label>
                            <textarea class="form-control" id="billing_notes" v-model="invoice.billing_notes" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="customerSignatureHeader">
                <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#customerSignatureCollapse" aria-expanded="true" aria-controls="customerSignatureCollapse">
                    CUSTOMER SIGNATURE
                </button>
                </h2>
                <div id="customerSignatureCollapse" class="accordion-collapse collapse show" aria-labelledby="customerSignatureHeader" >
                    <div class="accordion-body">
                        <div class="mb-3 col-md-6 col-12">
                            <signature id="signature_invoice" :img="invoice.signature" @onInput="signatureInput" style="margin-top:9px"/>
                        </div> 
                    </div>
                </div>
            </div>

        </div>

        <div class="col-12 clearfix" style="margin-top:15px">
            <router-link class="btn btn-danger float-end" style="margin-left:5px" to="/invoices" >Cancel <i class="fa-solid fa-xmark"></i> </router-link>
            <div class="btn-group dropend float-end">
                <button type="submit" class="btn btn-primary">Save <i class="far fa-save"></i></button>
                <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropright</span>
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" style="cursor:pointer" @click="submitInvoice(true)">Save and Close</a></li>
                    <!-- <li><a class="dropdown-item" style="cursor:pointer" @click="save(false, true)">Save and Open PDF's</a></li> -->
                </ul>
            </div>
            <template v-if="invoiceId">
                <a class="btn btn-light float-end" style="margin-right:10px" title="PDF invoice and Attachments" @click.prevent="openViewerPDF(null,'invoice&Attachments.pdf')" v-if="invoiceFilesCopy.length > 0"><i class="fa-solid fa-file-circle-plus"></i></a>
                <a class="btn btn-light float-end" style="margin-right:10px" title="PDF invoice" @click.prevent="openViewerPDF(null,'invoice.pdf')"><i class="fas fa-file-pdf"></i></a>
            </template>
        </div>
        <spinner ref="invoiceFormSpinner"/>
    </form>
</template>

<script>
import addressForm from '../addressForm'
import invoiceItems from '../items'
import fileInput from '../fileInput'
import signature from '../signature'
import fileList from '../fileList'
import request from '../../helpers/request'
import spinner from '../spinner'

import Vuex from 'vuex'
import moment from 'moment'

export default {
    name: "invoiceForm",
    components:{
        'file-input':fileInput,
        'address-form': addressForm,
        'invoice-items': invoiceItems,
        'signature': signature,
        'file-list': fileList,
        'spinner':  spinner

    },
    watch:{
        invoiceId: function(val){
            if(val!== null){
                this.getInvoice();
            }else{
                this.newInvoice();
            }
        },
    },
    computed:{
        _app(){
            return window.app
        },
		...Vuex.mapState({
            authUser: state => state.auth.user,
            authToken: state => state.auth.token,
        })
    },
    mounted(){
        if(this.invoiceId){
            this.getInvoice();
        }
    },
    props:{
        invoiceId:{
            type: String,
            default: null
        }
    },
    methods:{
        submitInvoice(close = false){
            this.save();
            this.saveAndClose = close;
        },
        removeInvoiceFiles(evt){
            this.invoiceFiles.splice(evt.index, 1);
        },
        setInvoiceNewFiles(files){
            this.invoice['files'] = files;
        },
        async save(){
            try{
                let res;
                this.$refs.invoiceFormSpinner.show();
                if(this.invoiceId){
                    if(this.invoice._id)delete this.invoice._id;
                    let formData = new FormData();
                    if(this.invoice.files && this.invoice.files.length > 0){
                        for(let file of this.invoice.files){
                            formData.append('files',file);
                        }
                        delete this.invoice.files;
                    }
                    this.invoice.files = this.invoiceFiles.map(f => f.name);
                    formData.append('invoiceId',this.invoiceId);
                    formData.append('invoice',JSON.stringify(this.invoice));
                    res = await request('POST', `/invoice/update?token=${this.authToken}`, formData, null, true);
                }else{
                    let formData = new FormData();
                    for(let file of this.invoice.files){
                        formData.append('files',file);
                    }
                    delete this.invoice.files;
                    formData.append('invoice',JSON.stringify(this.invoice));
                    res = await request('POST', `/invoice/create?token=${this.authToken}`, formData, null, true);
                    this.newInvoice();
                }
                setTimeout(() => {
                    this.$refs.invoiceFormSpinner.hide();
                    if(this.saveAndClose){
                        this.$router.push('/invoices');
                    }else{
                        this.$emit('onSave', {invoiceId:res._id, type:this.invoiceId?'edit':'create'});
                    }
                }, 1000);
            }catch(err){
                this.$refs.invoiceFormSpinner.hide();
                this.$emit('onError', err);
            }
        },
        async getInvoice(){
            try{
                this.invoice = await request('POST', '/invoice/read', {invoice: this.invoiceId}, this.authToken);
                let invoiceFiles = JSON.parse(JSON.stringify(this.invoice.files));
                for(let i = 0; i < invoiceFiles.length; i++){
                    invoiceFiles[i] = {'src':`${this._app.API}/invoice/${this.invoiceId}/${invoiceFiles[i]}?token=${this.authToken}`, 'name': invoiceFiles[i]}
                }
                this.invoiceFilesCopy = JSON.parse(JSON.stringify(invoiceFiles));
                this.invoiceFiles = invoiceFiles;
                this.$emit('onRead', this.invoice);
            }catch(err){
                console.log(err);
            }
        },
        signatureInput(evt){
            this.invoice.signature = evt;
        },
        async openViewerPDF(id, fileName = null){
            try{
                this.$refs.invoiceFormSpinner.show();
                let res = await request('POST',`/pdf/create`,{type:"invoice", typeId:this.invoiceId||id}, this.authToken);
                this.pdfFiles = res.files.map(f => {
                    return {'src':`${this._app.API}/invoice/${this.invoiceId||id}/pdf/${f}?token=${this.authToken}`, 'name': f}
                })
                this.$refs.invoiceFormSpinner.hide();
                setTimeout(() => {
                    for(let file of this.pdfFiles){
                        if(fileName !== null){
                            if(fileName == file.name) window.open(file.src, '_blank');
                        }else{
                            window.open(file.src, '_blank');
                        }
                    }
                }, 500);
            }catch(err){
                console.log(err);
            }
        },
        newInvoice(){
            this.pdfFiles = [];
            this.invoice = {
                installer_notes:null,
                billing_notes:null,
                insurance_carrier: null,
                claim_number:null,
                services:{
                    "Windshield Replacement": false,
                    "Required Calibration": false
                },
                customer: {
                    full_name: null,
                    home_phone: null,
                    cell_phone: null,
                    email: null,
                    date: `${moment().format('YYYY-MM-DD')}`,
                    address:{
                        country: 'United States',
                        address1: null,
                        address2: null,
                        city:null,
                        state: null,
                        zip: null
                    },
                    insurance_comp_agent: null,
                    insurance_comp_phone: null,
                    insurance_comp_fax: null,
                    policy_number: null,
                },
                vehicle:{
                    year: null,
                    manufacture: null,
                    model: null,
                    shade_windshield:null,
                    color:null,
                    features:[],
                    tag:null,
                    vin:null,
                    body_type:null,
                    date_loss:null,
                    date_time:null
                },
                description:{
                    items:[],
                    labor: null,
                    shop_suplies: null,
                    tax: null,
                    comments:null,
                },
                signature:null,
                files: []
            }
        }
    },
    data(){
        return{
            invoiceFiles: [],
            invoiceFilesCopy: [],
            pdfFiles: [],
            invoice:{
                installer_notes:null,
                billing_notes:null,
                insurance_carrier: null,
                claim_number:null,
                services:{
                    "Windshield Replacement": false,
                    "Required Calibration": false
                },
                customer: {
                    full_name: null,
                    home_phone: null,
                    cell_phone: null,
                    email: null,
                    date: `${moment().format('YYYY-MM-DD')}`,
                    address:{
                        country: 'United States',
                        address1: null,
                        address2: null,
                        city:null,
                        state: null,
                        zip: null
                    },
                    insurance_comp_agent: null,
                    insurance_comp_phone: null,
                    insurance_comp_fax: null,
                    policy_number: null,
                },
                vehicle:{
                    year: null,
                    manufacture: null,
                    model: null,
                    shade_windshield:null,
                    color:null,
                    features:[],
                    tag:null,
                    vin:null,
                    body_type:null,
                    date_loss:null,
                    date_time:null
                },
                description:{
                    items:[],
                    labor: null,
                    shop_suplies: null,
                    tax: null,
                    comments:null
                },
                signature:null,
                files: [],
                saveAndClose: false,
            }
        }
    }
}
</script>