<template>
    <div class="row">

        <div class="mb-3 col-md-7 col-12">
            <label for="address_address1">Address</label>
            <input type="text" class="form-control" id="address_address1" v-model="cAddress.address1" required>
        </div>

        <div class="mb-3 col-md-5 col-12">
            <label for="address_address2"></label>
            <input type="text" class="form-control" id="address_address2" placeholder="Apt/suite" v-model="cAddress.address2">
        </div>

        <div class="mb-3 col-md-4 col-12">
            <label for="address_country">Country</label>
            <select id="address_country" class="form-control" v-model="cAddress.country">
                <option :value="country.name" v-for="country in countries">{{country.name}}</option>
            </select>
        </div>

        <div class="mb-3 col-md-3 col-12" v-if="cAddress.country === 'United States'">
            <label for="address_states">State</label>
            <select id="address_states" class="form-control" v-model="cAddress.state" required>
                <option :value="state.code" v-for="state in states">{{state.name}}</option>
            </select>
        </div>

        <div class="mb-3 col-md-3 col-12">
            <label for="address_city">City</label>
            <input type="text" class="form-control" id="address_city" v-model="cAddress.city" required>
        </div>

        <div class="mb-3 col-md-2 col-12">
            <label for="address_zip">ZIP code</label>
            <input type="text" class="form-control" id="address_zip" v-model="cAddress.zip" required>
        </div>
    </div>
</template>

<script>
import countries from '../../assets/js/countries.js'
import states from '../../assets/js/states.js'

export default {
    name: "addressForm",
    props:{
        address:{
            type: Object,
            required: true
        }
    },
    watch:{
        address:{
            handler: function(val){
                this.cAddress = val;
            },
            deep:true
        },
        cAddress:{
            handler: function(val){
                this.$emit('update:address', val);
            },
            deep:true,
        }
    }, 
    data(){
        return{
            cAddress: this.address,
            countries: countries,
            states: states,
        }
    }
}
</script>