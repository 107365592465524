<template>
    <div class="container">
        <alert ref="invoiceListAlert" :body="alert.message" :type="alert.type"/>
        <router-link type="button" class="btn btn-primary" to="/invoice/create" style="float:right;">New <i class="fas fa-plus"></i></router-link>
        <h2>Invoices</h2>
        <div class="card" style="margin-top:10px">
            <custom-table source="/invoice/list" ref="invoiceList" :enableTopPagination="true" :options="{sort:{number:-1}}" :enableSearch="true">
                <template #header>
                    <th  width="20%">Invoice</th>
                    <th  width="35%">Customer Name</th>
                    <th  width="10%">Date</th>
                    <th width="5%">Fax</th>
                    <th width="30%"></th>
                </template>
                <template #body="{row, index}">
                    <td  width="20%"><router-link class="text-decoration-none" :to="`/invoice/edit/${row._id }`"> {{ row.number }} </router-link></td>
                    <td  width="35%"><router-link class="text-decoration-none" :to="`/invoice/edit/${row._id }`">{{ row.customer.full_name }}</router-link></td>
                    <td  width="10%">{{ row.customer.date }} </td>
                    <td  width="5%">
                        <router-link v-if="row.faxSend " class="btn btn-link btn-sm" title="Fax Sended List" :to="`/invoice/faxes/${row._id }`"><i class="fas fa-eye"></i></router-link>
                    </td>
                    <td width="30%">
                        <router-link class="btn btn-link btn-sm" title="Edit invoice" :to="`/invoice/edit/${row._id }`"><i class="fas fa-edit"></i></router-link>
                        <button class="btn btn-link btn-sm" title="Delete invoice" @click="deleteInvoice(row._id)"><i class="fas fa-trash-alt"></i></button>
                        <button class="btn btn-link btn-sm" title="PDF invoice" @click="openViewerPDF(row._id, 'invoice.pdf')"><i class="fas fa-file-pdf"></i></button>
                        <button class="btn btn-link btn-sm" title="PDF invoice" @click="openViewerPDF(row._id, 'invoice&Attachments.pdf')" v-if="row.files.length > 0"><i class="fa-solid fa-file-circle-plus"></i></button>
                        <button class="btn btn-link btn-sm" title="Send Email invoice" @click="openEmail(row)"><i class="fas fa-envelope"></i></button>
                        <button class="btn btn-link btn-sm" title="Send Fax invoice" @click="openFax(row)"><i class="fa-solid fa-fax"></i></button>
                    </td>
                </template>
            </custom-table>
        </div>
        <email-modal 
            ref="invoice-email-modal" 
            type="invoice" 
            :typeId="email.typeId" 
            :recipient="email.recipient"
            :enableAttachments="email.files > 0" 
            @onSend="openAlert('Email sent successfully', 'success')" 
            @onError="openAlert('cannot send Email', 'danger')" 
        />
        <fax-modal 
            ref="invoice-fax-modal" 
            type="invoice" 
            :typeId="fax.typeId" 
            :toNumber="fax.toNumber"
            :enableAttachments="fax.files > 0"
            @onSend="openAlert('Fax sent successfully', 'success', true)" 
            @onError="openAlert('cannot send Fax', 'danger')" 
        />
    </div>
</template>

<script>
import table from '../../../components/table'
import request from '../../../helpers/request'
import emailModal from '../../../components/emailModal'
import faxModal from '../../../components/faxModal'
import alert from '../../../components/alert'
import Vuex from 'vuex'

export default {
  name: 'invoices',
  components:{
    'custom-table': table,
    'email-modal': emailModal,
    'fax-modal' : faxModal,
    'alert': alert
  },
  computed:{
    _app(){return window.app},
	...Vuex.mapState({
      auth: state => state.auth.user,
      authToken: state => state.auth.token,
    })
  },   
  methods:{
    openAlert(msg, type, reload = false){
        this.alert.message = msg;
        this.alert.type = type;
        if(reload) this.$refs.invoiceList.getRecords();
        this.$refs['invoiceListAlert'].open();
        setTimeout(() => {
            this.$refs['invoiceListAlert'].close();
        }, 2500);
    },  
    async deleteInvoice(invoiceId){
        try{
            const confirm = window.confirm("Are you sure, of delete this invoice?")
            if(confirm){
                await request('POST', '/invoice/delete', {invoice: invoiceId}, this.authToken);
                this.openAlert('Invoice deleted successfully', 'success');
                this.$refs.invoiceList.getRecords();
            }
        }catch(err){
            this.openAlert(`cannot deleted invoice : ${err}`,'danger');
        }
    },
    async openEmail(invoice){
        this.email.typeId = invoice._id;
        this.email.recipient = invoice.customer.email;
        this.email.files = invoice.files.length;
        this.$refs['invoice-email-modal'].show();
        
    },

    async openFax(invoice){
        this.fax.typeId = invoice._id;
        this.fax.toNumber = invoice.customer.insurance_comp_fax;
        this.fax.files = invoice.files.length;
        this.$refs['invoice-fax-modal'].show();
    },
    async openViewerPDF(invoiceId, fileName){
        try{
            let res = await request('POST',`/pdf/create`,{type:"invoice", typeId:invoiceId}, this.authToken);
            this.files = res.files.map(f => {
                return {'src':`${this._app.API}/invoice/${invoiceId}/pdf/${f}?token=${this.authToken}`, 'name': f}
            })
            for(let file of this.files){
                if(fileName !== null){
                    if(fileName == file.name) window.open(file.src, '_blank');
                }else{
                    window.open(file.src, '_blank');
                }
            }
        }catch(err){
            console.log(err);
        }
    }
  },
  data(){
      return {
        invoices : [],
        email :{
            recipient: null,
            typeId: null,
            files: 0,
            type: 'invoice'
        },
        fax :{
            toNumber: null,
            typeId: null,
            files: 0,
            type: 'invoice'
        },
        alert:{
            message: null,
            type: null
        },
        files : []
      }
  }
}
</script>