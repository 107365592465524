import axios from 'axios'
import store from '../../store'
import settings from '../../../config.js'

export default async(method, url, data = null, token, formData = false, fullResult = false, responseType = undefined) => {
    try {
        let host = settings.API ? settings.API : "http://localhost";
        let aux = !formData ? `${url}?_=${new Date().getTime()}` : url;
        let res = await axios({
            url: `${host}${aux}`,
            method: method ? method : 'POST',
            cache: false,
            data: !formData ? (data ? {...data, token: token ? token : undefined } : { token: token ? token : undefined }) : data,
            responseType: responseType // important
        })
        return fullResult?res:res.data;
    } catch (error) {
        console.log(error);
        if (error.response.status == 401) {
            store.dispatch('auth/logout');
            window.location.reload();
        } else if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw error.request;
        } else {
            throw error.message;
        }
    }
}