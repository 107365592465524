<template>
  <div>
    <header-menu/>
    <router-view style="padding-top:20px; padding-bottom: 120px;"></router-view>
    <footer class="fixed-bottom container-fluid text-bg-light">
      <p class="text-center">DoralAutoGlass CRM© 2022 • All Rights Reserved</p>
    </footer>
  </div>
</template>
<script>
import headerMenu from './../../components/headerMenu'
export default {
  name: 'main',
  components: {
    'header-menu' : headerMenu,
  }
}
</script>

<style>
footer {
  padding-top: 10px;
  color: white;
  font-size: 16px;
}
</style>