<template>
    <div>
        <Vue3Signature 
            class="signature" 
            :ref="`signature-${id}`" 
            :w="'100%'" 
            :h="'150px'" 
            style="margin: 0 auto" 
            :disabled="disabled"
            :sigOption="{ 'onBegin': onBegin, 'onEnd':onEnd }">
        </Vue3Signature>
        <a class="btn btn-link btn-sm" @click.prevent="clear" >Clear Signature</a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'signature',
    props:{
        id:{
            required:true,
        },
        img:{
            default: null,
        },
        disabled:{
            type: Boolean,
            default: false
        }
    },
    watch:{
        img(val){
            this.$refs[`signature-${this.id}`].fromDataURL(val);
        }
    },
    mounted(){
        if(this.img){
            this.$refs[`signature-${this.id}`].fromDataURL(this.img);
        }
    },

    data() {
      return {
        display: false,
        text: null
      }
    },
    methods: {
      onBegin() {},
      onEnd() {
        this.$emit('onInput', this.$refs[`signature-${this.id}`].save());
      },
      clear(){
        this.$refs[`signature-${this.id}`].clear();
        this.$emit('onInput', null);
      }
    }
  }
  </script>

<style>

.signature {
    border: 2px solid #000;
    border-radius: 5px;
    background-origin: border-box;
    background-clip: content-box, border-box;
}

</style>
  




