import store from '../store'
import { createRouter, createWebHistory } from 'vue-router'
//import pages/views
import main from '../views/private/main.vue'
import login from '../views/public/login.vue'
import dashboard from '../views/private/dashboard.vue'
import users from '../views/private/users.vue'
import invoice from '../views/private/invoice/invoice.vue'
import invoices from '../views/private/invoice/list.vue'
import quote from '../views/private/quote/quote.vue'
import quotes from '../views/private/quote/list.vue'
import invoiceFaxes from '../views/private/invoice/invoiceFaxes.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: { middlewarelogin: true },
  },
  {
    path: '/',
    component: main,
    meta: { middlewareAuth: true },
    children: [
      {
        name: 'dashboard',
        path: '/',
        component: dashboard,
      },
      {
        name: 'users',
        path: '/users',
        component: users,
      },
      {
        name: 'invoices',
        path: '/invoices',
        component: invoices,
      },
      {
        name: 'invoice',
        path: '/invoice/:type/:id?',
        component: invoice,
        meta: { middlewareType: true },
      },
      {
        name: 'quotes',
        path: '/quotes',
        component: quotes,
      },
      {
        name: 'quote',
        path: '/quote/:type/:id?',
        component: quote,
        meta: { middlewareType: true },
      },
      {
        name: 'invoiceFaxes',
        path: '/invoice/faxes/:id',
        component: invoiceFaxes,
      },
      {
        path: '/:pathMatch(.*)*',
        component: dashboard
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async(to, from, next) => {
  if (to.matched.some(record => record.meta.middlewareAuth)) {
      if (!store.state.auth.token) {
        next({ path: '/login' });
      }
  } else if (to.matched.some(record => record.meta.middlewarelogin)) {
      if (store.state.auth.user && store.state.auth.token) {
        next({ path: '/' });
      }
  }
  if(to.matched.some(record => record.meta.middlewareType)){
    if(to.params.type == 'create'){
      next();
    }else if(to.params.type == 'edit'){
      if(to.params.id && to.params.id !== "" ){
        next();
      }
    }
    next({ path: '/' });
  }
  
  next();
})

export default router
