<template>
  <div class="container" style="margin-top:10%">
    <div class="row">
      <div class="col-md-4 offset-md-3">
        <h2 class="text-center">Doral <small>CRM</small></h2>
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="userEmail">Email</label>
            <input type="email" class="form-control" id="userEmail" placeholder="Enter email" v-model="user.email" required="true">
          </div>
          <div class="form-group">
            <label for="userPassword">Password</label>
            <input type="password" class="form-control" id="userPassword" placeholder="Password" v-model="user.password" required="true">
          </div>
          <div class="row col-12 text-danger" v-show="status.fail">
            <strong> {{ status.msg }} </strong> 
          </div> 
          <button style="margin-top:30px" type="submit" class="w-100 btn btn-block btn-primary"> Sign in <i class="fas fa-sign-in-alt"></i> </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import request from './../../helpers/request'
export default {
  name: 'login',
  methods:{
    async login(){
      try{
        const res = await request('POST','/login',this.user);
        if(res.token){
          this.$store.dispatch("auth/login", res.token);
          this.$router.push('/');
        }else{
          throw "Invalid Token";
        }
      }catch(err){
        this.status.fail = true;
        this.status.msg = "Username and/or Password invalid";
        setTimeout(()=>{ 
          this.status.fail = false;
          this.status.msg = "";
        }, 5000);	
      }
    }
  },
  data(){
    return {
      status:{
				fail : false,
        msg : "",
      },
      user:{
        email: null,
        password: null
      }
    }
  }
}
</script>
