<template>
    <div class="modal fade" id="faxModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Send Fax</h5>
            </div>
            <div class="modal-body">
                <form>
                    <div class="mb-3">
                        <label for="to_number" class="col-form-label">Sen To:</label>
                        <input type="number" class="form-control" id="to_number" v-model="_toNumber" :disabled="loading" required>
                    </div>
                    <div class="mb-3" v-if="(type == 'invoice' && _enableAttachments)">
                        <label for="attachment" class="col-form-label">Attachment:</label>
                        <select class="form-select" id="attachment" v-model="attachment">
                            <option value="I">Invoice</option>
                            <option value="I&A">Invoice & Attachment</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal" :disabled="loading">Cancel <i class="fa-solid fa-xmark"></i></button>
                <button type="button" class="btn btn-primary btn-sm" @click.prevent="sendFax" :disabled="loading">
                    <template v-if="!loading"> Send <i class="fa-regular fa-envelope"></i> </template>
                    <template v-else> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sended... </template>
                </button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import request from '../../helpers/request'
import {Modal} from 'bootstrap'
import Vuex from 'vuex'

export default {
    name: 'faxModal',
    props:{
        type:{
            type: String,
        },
        typeId:{
            type: String,
        },
        toNumber:{
            type: String,
            default: null
        },
        enableAttachments:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        ...Vuex.mapState({
            auth: state => state.auth.user,
            authToken: state => state.auth.token,
        })
    },
    watch:{
        toNumber(val){
            this._toNumber = val;
        },
        enableAttachments(val){
            this._enableAttachments = val;
        }
    },
    mounted(){
        this.faxModal = new Modal(document.getElementById('faxModal'));
    },
    methods:{
        close(){
            this.faxModal.hide();
            this._enableAttachments = false;
        },
        show(){
            this.attachment = 'I',
            this.faxModal.show();
        },
        async sendFax(){
            try{
                this.loading = true;
                let data = {toNumber:this._toNumber,type: this.type, typeId: this.typeId};
                if(this.type == 'invoice') data['attachment'] = this.attachment; 
                await request('POST', '/fax/send', data ,this.authToken);
                setTimeout(() => {
                    this.close();
                    this.loading = false;
                    this.$emit('onSend', {success: true});
                }, 1500);
            }catch(err){
                setTimeout(() => {
                    this.close();
                    this.loading = false;
                    this.$emit('onError', {error: err});
                }, 1500);
            } 
        }
    },  
    data(){
        return {
            faxModal:null,
            attachment: 'I',
            loading: false,
            _toNumber: this.toNumber,
            _enableAttachments: this.enableAttachments,
        }
    }
}
</script>