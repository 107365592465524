<template>
    <nav aria-label="breadcrumb" v-if="routes.length > 0">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" :class="[{'active':route.active }]" v-for="(route, index) in routes">
                <template v-if="route.active">
                     {{ route.name }}
                </template>
                <template v-else>
                    <router-link :to="route.path">{{ route.name }}</router-link>
                </template>
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
    name: 'breadcrum',
    props:{
        routes:{
            type: Array,
            default: () => []
        }
    }
}
</script>