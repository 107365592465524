<template>
    <div class="container">
        <h2>Invoice <small class="text-muted" v-if="invoiceNumber">No. {{invoiceNumber}}</small> </h2>
        <div class="row">
            <div class="col-12">
                <alert ref="invoiceListAlert" :body="alert.message" :type="alert.type"/>
                <invoice-form :invoiceId="id" @onSave="onSave" @onError="onError"/>
            </div>
        </div>
    </div>
  </template>
  <script>
    import alert from '../../../components/alert';
    import invoiceForm from "../../../components/invoiceForm"
    export default {
        name: 'invoice',
        components: {
            'invoice-form' : invoiceForm,
            'alert': alert
        },
        methods:{
            openAlert(msg, type){
                this.alert.message = msg;
                this.alert.type = type;
                this.$refs['invoiceListAlert'].open();
                setTimeout(() => {
                    this.$refs['invoiceListAlert'].close();
                }, 3500);
            },
            onRead(evt){
                this.invoiceNumber = evt.number;
            },  
            onSave(evt){
                this.openAlert(`Invoice ${evt.type=='create'?'Created':'Updated'} Succesfully`, 'success');
                window.location.href = `/invoice/edit/${evt.invoiceId}`;
            },
            onError(err){
                let msg = 'Invalid type of File valid types(.jpg , .png and .pdf)';
                this.openAlert(`cannot save invoice: ${msg}`, 'danger');
                setTimeout(() => {
                    window.location.reload();
                }, 3505);
            }
        },  
        computed:{
            type(){
                return this.$route.params.type;
            },
            id(){
                return this.$route.params.id||null;
            }
        },
        data(){
            return {
                alert:{
                    message: null,
                    type: null
                },
                invoiceNumber: null
            }
        }
    }
  </script>