<template>
    <div class="container">
        <div class="row">
          <div class="col-3">
            <div class="card bg-light" @click="$router.push('/invoices')" style="cursor:pointer">
              <div class="card-header">Invoices</div>
              <div class="card-body">
                <h1 class="card-title"><i class="fa-solid fa-file-invoice-dollar"></i></h1>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card bg-light" @click="$router.push('/quotes')" style="cursor:pointer">
              <div class="card-header">Quotes</div>
              <div class="card-body">
                <h1 class="card-title"><i class="fa-solid fa-file-invoice"></i></h1>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="card bg-light" @click="$router.push('/users')" style="cursor:pointer">
              <div class="card-header">Users</div>
              <div class="card-body">
                <h1 class="card-title"><i class="fa-solid fa-users"></i></h1>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'dashboard',
}
</script>
