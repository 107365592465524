<template>
    <div>
        <button type="button" class="btn btn-primary btn-sm" @click="openNewItem()">Add <i class="fas fa-plus"></i></button>
        <div style="margin:10px">
            <div class="row">
                <div class="col col-header"> Desc </div>
                <div class="col col-header d-none d-sm-none d-md-block"> Qty </div>
                <div class="col col-header d-none d-sm-none d-md-block"> Price </div>
                <div class="col col-header"> Status </div>
                <div class="col col-header"></div>
            </div>


            <div class="row" v-for="(item,index) in cItems" v-if="cItems.length > 0">
                <div class="col col-body">
                    {{item.description}}
                </div>
                <div class="col col-body d-none d-sm-none d-md-block">
                    {{item.quantity}}
                </div>
                <div class="col col-body d-none d-sm-none d-md-block">
                    $ {{Number(item.price).toFixed(2)}}
                </div>
                <div class="col col-body">
                    {{item.status}}
                </div>
                <div class="col col-body">
                    <button type="link" class="btn btn-link btn-sm" @click.prevent="openEditItem(item, index)"><i class="fas fa-edit"></i></button>
                    <button type="link" class="btn btn-link btn-sm" @click.prevent="deleteItem(index)" style="color:red"><i class="fas fa-trash"></i></button>
                </div>
            </div>

    
            <div class="row" v-else>
                <div class="col col-body">
                    No items Added
                </div>
            </div>
   
        </div>

        <!-- Modal -->
        <div class="modal fade" id="itemFormModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Item</h5>
                        <button type="button" class="btn-close" @click="cancelItem" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form class="row" @submit.prevent="saveItem">
                            <div class="mb-3 col-12">
                                <label for="item_quantity" class="form-label">QTY</label>
                                <input type="number" class="form-control form-control-sm" id="item_quantity" v-model="item.quantity" required>
                            </div>
                            <div class="mb-3 col-12">
                                <label for="item_part" class="form-label">Part No.</label>
                                <input type="text" class="form-control form-control-sm" id="item_part" v-model="item.part">
                            </div>
                            <div class="mb-3 col-12">
                                <label for="item_description" class="form-label">Description</label>
                                <input type="text" class="form-control form-control-sm" id="item_description" v-model="item.description" required>
                            </div>

                            <div class="mb-3 col-12">
                                <label for="item_price" class="form-label">Price</label>
                                $ <input type="number" step="any" class="form-control form-control-sm" id="item_price" v-model="item.price" required>
                            </div>

                            <div class="mb-3 col-12">
                                <label class="form-label">Status</label>
                                <div class="form-check" v-for="status in statusList">
                                    <input class="form-check-input" type="radio" :value="status" :id="`${status}_fr`" v-model="item.status" required>
                                    <label class="form-check-label" :for="`${status}_fr`">
                                    {{status}}
                                    </label>
                                </div>
                            </div>
                            <div class="mb-3 col-12 clearfix">
                                <button class="btn btn-danger btn-sm float-end" style="margin-left:4px" @click.prevent="cancelItem">Cancel <i class="fa-solid fa-xmark fw"></i> </button>
                                <button class="btn btn-primary btn-sm float-end ml-4" type="submit">Save <i class="fa-solid fa-save fw"></i> </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {Modal} from 'bootstrap'
export default {
    name: 'items',
    props:{
        items:{
            type: Array,
            required: true
        }
    },
    watch:{
        items:{
            handler: function(val){
                this.cItems = val;
            },
            deep: true
        },
        cItems:{
            handler: function(val){
                this.$emit('update:items', val);
            },
            deep: true
        }
    },
    mounted(){
        this.itemModal = new Modal(document.getElementById('itemFormModal'));
    },
    methods:{
        cancelItem(){
            this.itemModal.hide();
            this.item = {
                quantity: null,
                part: null,
                description: null,
                price: null,
                status: null
            };
            this.indexEditItem = null;
        },
        saveItem(){
            if(this.indexEditItem !== null){
                this.cItems[this.indexEditItem] = JSON.parse(JSON.stringify(this.item));
            }else{
                this.cItems.push(this.item);
            }
            this.cancelItem();
        },
        openNewItem(){
            this.item = {
                quantity: null,
                part: null,
                description: null,
                price: null,
                status: 'Published'
            };
            this.indexEditItem = null;
            this.itemModal.show();
        },
        openEditItem(item, index){
            this.item = JSON.parse(JSON.stringify(item));
            this.indexEditItem = index;
            this.itemModal.show();
        },
        deleteItem(index){
            let conf = window.confirm("Are you sure to delete this Item");
            if(conf){
                this.cItems.splice(index, 1);
            }   
        }
    },  
    data(){
        return {
            itemModal:null,
            cItems: this.items,
            item:{
                quantity: null,
                part: null,
                description: null,
                price: null,
                status: 'Published'
            },
            indexEditItem:null,
            statusList:['Published','Unpublished']
        }
    }
}
</script>

<style>
.col-header {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    background-color: rgba(86,61,124,.15);
    border: 1px solid rgba(86,61,124,.2);
}

.col-body {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: 1px solid rgba(86,61,124,.2);
}

</style>