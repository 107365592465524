<template>
    <div class="container">
        <alert ref="faxListAlert" :body="alert.message" :type="alert.type"/>
        <h2>Fax <small class="text-muted">Receipts</small> </h2>
        <div class="card" style="margin-top:10px">
            <custom-table source="/fax/list" ref="faxList" :options="{filters:{invoice:invoice},sort:{number:-1}}">
                <template #header>
                    <th  width="20%">To (Fax #)</th>
                    <th  width="20%">Date Sended</th>
                    <th  width="20%">Status</th>
                    <th  width="10%">Receipt</th>
                </template>
                <template #body="{row, index}">
                    <td  width="20%">{{ row.to}} </td>
                    <td  width="20%">{{ row.createdDate }} </td>
                    <td  width="20%"> 
                        <span class="text-success" style="cursor:pointer" v-if="row.status_code && row.status_code == '201'" @click="openViewerPDF(row._id)"> SENT </span>
                        <span class="text-danger" v-else-if="row.status_code"> row.status_code</span>
                        <span class="text-secondary" v-else> SENDED </span>
                    </td>
                    <td width="10%">
                        <button class="btn btn-link btn-sm" title="PDF fax" v-if="!row.status_code || row.status_code != '201'" @click.prevent="getReceipt(row.message_id)"> <i class="fas fa-eye"></i></button>
                    </td>
                </template>
            </custom-table>
        </div>
        <spinner ref="invoiceFaxesSpinner"/>
        <file-viewer ref="v_fax_pdf" id="v_fax_pdf" :files="files" />
    </div>
</template>

<script>
import table from '../../../components/table'
import request from '../../../helpers/request'
import isMobile from '../../../helpers/isMobile'
import fileViewer from '../../../components/fileViewer';
import alert from '../../../components/alert';
import spinner from '../../../components/spinner';
import Vuex from 'vuex'

export default {
  name: 'invoiceFaxes',
  components:{
    'custom-table': table,
    'file-viewer':fileViewer,
    'spinner':  spinner,
    'alert': alert
  },
  computed:{
    _app(){return window.app},
	...Vuex.mapState({
      auth: state => state.auth.user,
      authToken: state => state.auth.token,
    }),
    invoice(){
        return this.$route.params.id||null;
    }
  },   
  methods:{
    openAlert(msg, type){
        this.alert.message = msg;
        this.alert.type = type;
        this.$refs['faxListAlert'].open();
        setTimeout(() => {
            this.$refs['faxListAlert'].close();
        }, 2500);
    },  
    async deletefax(faxId){
        try{
            const confirm = window.confirm("Are you sure, of delete this fax?")
            if(confirm){
                this.$refs.invoiceFaxesSpinner.show();
                await request('POST', '/fax/delete', {fax: faxId}, this.authToken);
                this.openAlert('fax deleted successfully', 'success');
                this.$refs.invoiceFaxesSpinner.hide();
                this.$refs.faxList.getRecords();
            }
        }catch(err){
            this.openAlert(`cannot deleted fax : ${err}`,'danger');
            this.$refs.invoiceFaxesSpinner.hide();
        }
    },
    async getReceipt(messageId){
        try{
            this.$refs.invoiceFaxesSpinner.show();
            await request('POST', '/fax/receipt', {messageId: messageId}, this.authToken);
            this.openAlert('Fax Receipt received successfully', 'success');
            this.$refs.invoiceFaxesSpinner.hide();
            this.$refs.faxList.getRecords();
        }catch(err){
            this.openAlert(`receipt fax not found, please check later`,'danger');
            this.$refs.invoiceFaxesSpinner.hide();
        }
    },
    async openViewerPDF(faxId){
        try{
            this.files = [{'src':`${this._app.API}/fax/${faxId}/pdf/faxReceipt.pdf?token=${this.authToken}`, 'name': 'faxReceipt.pdf'}];
            if(!isMobile())this.$refs['v_fax_pdf'].open();
            else {
                for(let file of this.files){
                    window.open(file.src, '_blank');
                }
            }
        }catch(err){
            console.log(err);
        }
    }

  },
  data(){
      return {
        faxs : [],
        email :{
            recipient: null,
            typeId: null,
            type: 'fax'
        },
        alert:{
            message: null,
            type: null
        },
        files : []
      }
  }
}
</script>